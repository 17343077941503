import { handleTeamChangeReq } from "../../scripts/dataHandler";

function TeamChangeApproval({reqs, key}) {

    const mapReqs = (reqs) => {
        return reqs.map(r => {
           return <div className="column centerAlign"><div className="row">
                <div className="column">
                    <h3>Old data</h3>
                    <h3>{"Name: " + r.oldData.name}</h3>
                    <h3>{"Motto: " + r.oldData.motto}</h3>
                    <h3>{"Email: " + r.oldData.email}</h3>
                    <img src={r.oldData.pfp} style={{width: "20vw", height: "20vw"}} alt="old pfp"/>
                </div>
                <div className="column" style={{marginLeft: "2vw"}}>
                    <h3>New data</h3>
                    <h3>{"Name: " + r.newTeamName}</h3>
                    <h3>{"Motto: " + r.newMotto}</h3>
                    <h3>{"Email: " + r.newEmail}</h3>
                    <img src={r.newPfp} style={{width: "20vw", height: "20vw"}} alt="new pfp"/>
                </div>
            </div>
            <div className="row">
                <button onClick={() => {handleTeamChangeReq(key,r.id,true)}}>Potvrdit</button>
                <button onClick={() => {handleTeamChangeReq(key,r.id,false)}}>Zahodit</button>
            </div>
            </div>
        })
    }

    return ( 
    <div className="column">
        {reqs ? mapReqs(reqs) : <h2>zadne :(</h2>}
    </div>
     );
}

export default TeamChangeApproval;