import { useEffect, useState } from "react";
import { getMsgReqs, getTeamChangeReqs, getTeams } from "../scripts/dataHandler";
import TeamList from "./Sections/TeamList";
import TeamChangeApproval from "./Sections/TeamChangeApproval";
import ChatMsgApproval from "./Sections/ChatMsgApproval";
import { Routes, Route, Link } from "react-router-dom";
import CertGen from "./Sections/CertGen";
import MailSender from "./Sections/MailSender";
import GoBack from "../components/GoBack";

function MainPage({adminKey}) {

    let [teams,setTeams] = useState(undefined)
    let [changeReqs, setChangeReqs] = useState(undefined)
    let [msgReqs, setMsgReqs] = useState(undefined)
    let [msg,setMsg] = useState("")

    const getData = () => {
        getTeams(adminKey,setMsg,setTeams)
        getTeamChangeReqs(adminKey,setChangeReqs)
        getMsgReqs(adminKey,setMsgReqs)
    }

    useEffect(() => {
        console.log(adminKey)
        setTimeout(getData,1000)
    },[adminKey])

    return ( <div className="container column centerAlign">
      <div style={{fontSize: "5vw", position: "absolute", left: "2vw", top:"2vw"}}><GoBack link=""/></div>
    <h1>Admin page</h1>
    <h2>{teams && `Počet týmů: ${teams.length - 2} (asi)`}</h2>
    <h2>{msg}</h2>

    <Routes>
          <Route path="/" element={<div className="row">
            <Link to="/teams"><button>teams</button></Link>
            <Link to="/chatReqs"><button>chat reqs</button></Link>
            <Link to="/teamReqs"><button>team change reqs</button></Link>
            <Link to="/certGen"><button>cert gen</button></Link>
            <Link to="/sendMail"><button>send mail</button></Link>
          </div>} />
          <Route path="/teams" element={<><h2>Teams</h2><TeamList teams={teams} key={adminKey}/></>}/>
          <Route path="/chatReqs" element={<><h2>Chat zprávy</h2><ChatMsgApproval msgs={msgReqs} key={adminKey}/></>}/>
          <Route path="/teamReqs" element={<><h2>Potvrzování změn</h2><TeamChangeApproval reqs={changeReqs} key={adminKey}/></>}/>
          <Route path="/certGen" element={<CertGen key={adminKey}/>}/>
          <Route path="/sendMail" element={<MailSender key={adminKey} teams={teams}/>}/>
        </Routes>
    
</div>);
}

export default MainPage;