import { deleteTeam } from "../scripts/dataHandler";

function TeamCard({team, pos, adminKey}) {

    const teamDelete = () => {
        const name = team.name
        if (window.confirm("Opravdu chceš vymazat team " + name + "?")) {
            deleteTeam(adminKey,name,alert)
        }
    }

  return (
    <div className="teamCard row">
        <h2 style={{margin: "0vw"}}>{"#" + pos}</h2>
      <img
        className="pfp"
        src={team.pfp}
        alt={"Ikona týmu " + team.name}
      />
      <div className="column">
        <h1 style={{margin: "0vw"}}>{team.name}</h1>
        <h2 style={{margin: "0vw"}}>{team.points + " bodů"}</h2>
      </div>

    <div onClick={teamDelete}>
      <ion-icon name={"close-outline"} style={{color: "red",width: "4vw", height: "4vw"}}/>
      </div>

    </div>
  );
}

export default TeamCard;
