import { generateCert, generateCertificates } from "../../scripts/dataHandler";

function CertGen({key}) {
    return ( 
        <div className="centerAlign column">
            <h1>DISCONTINUED, MOVED TO MAILSENDER</h1>
            <button onClick={() => {generateCert(key)}}>hahaha</button>
            <button onClick={() => {generateCertificates(key,["ŠMouLa","Duch","šmoula"])}}>mail senderrere</button>
        </div>
     );
}

export default CertGen;