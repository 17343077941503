import {Link} from "react-router-dom"

function GoBack({link}) {
    return ( 
        <Link to={link} className="resetLink">
            <div className="goBack">
            <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
        </Link>
     );
}

export default GoBack;