import { sendGeneralMail, sendTeamRecapMail, generateCertificates } from "../../scripts/dataHandler";
import { useState } from "react";

function MailSender({ key, teams }) {
  let [data, setData] = useState({
    subject: "",
    text: "",
    content: ``,
    selectedTeams: [],
  });

  const handleChange = (e) => {
    let temp = data;
    temp[e.target.id] = e.target.value;
    setData(temp);
  };

  /*const handleTeamSelect = (e) => {
        let temp = data
        if (e.target.checked && !(temp.selectedTeams.includes(e.target.id))) {
          temp.selectedTeams.push(e.target.id)
        } else if (!(e.target.checked)) {
          temp.selectedTeams.pop(temp.selectedTeams.indexOf(e.target.id))
        }
        setData(temp)
        console.log(data)
    }*/

  const mapTeamSelect = (teams) => {
    return teams.map((t) => (
      <div className="row" key={t.id}>
        <input
          type="checkbox"
          className="teamCheck"
          id={t.name} /*onChange={(e) => {handleTeamSelect(e)}}*/
        />
        <p>{t.name}</p>
      </div>
    ));
  };

  const checkAll = (e) => {
    let boxes = Array.from(document.getElementsByClassName("teamCheck"));
    boxes.forEach((box) => {
      box.checked = e.target.checked;
    });
  };

  const getSelected = () => {
    let boxes = Array.from(document.getElementsByClassName("teamCheck"));
    boxes = boxes.filter((b) => b.checked).map((b) => b.id);
    return boxes;
  };

  const send = () => {
    const selectedTeams = getSelected();
    if (selectedTeams.length > 0) {
      if (
        window.confirm(`Jsou všechna data správně?\nPředmět: ${data.subject}`)
      ) {
        sendGeneralMail(
          key,
          data.subject,
          data.text,
          data.content,
          selectedTeams
        );
      }
    } else {
      window.alert("Nejsou vybrány žádné týmy");
    }
  };

  const sendRecap = () => {
    const selectedTeams = getSelected();
    if (selectedTeams.length > 0) {
      if (
        window.confirm(`Jsou všechna data správně?\nTýmy:\n ${selectedTeams.map(t => `${t}\n`)}`)
      ) {
        sendTeamRecapMail(key, selectedTeams);
      }
    } else {
      window.alert("Nejsou vybrány žádné týmy");
    }
  };

  const sendCertificates = () => {
    const selectedTeams = getSelected();
    if (selectedTeams.length > 0) {
      if (
        window.confirm(`Jsou všechna data správně?\nTýmy:\n ${selectedTeams.map(t => `${t}\n`)}`)
      ) {
        generateCertificates(key, selectedTeams);
      }
    } else {
      window.alert("Nejsou vybrány žádné týmy");
    }
  }

  return (
    <div className="centerAlign column">
      <h1>Mail sender</h1>
      <h2>Subject</h2>
      <input type="text" id="subject" onChange={(e) => handleChange(e)} />
      <h2>Content</h2>
      <textarea type="text" id="content" onChange={(e) => handleChange(e)} />
      <div className="column">
        <div className="row">
          <input
            type="checkbox"
            id="all"
            onChange={(e) => {
              checkAll(e);
            }}
          />
          <h2>Select all</h2>
        </div>
        {teams && mapTeamSelect(teams)}
      </div>
      <button onClick={send}>send general mail</button>
      <button onClick={sendRecap}>send team recap</button>
      <button onClick={sendCertificates}>send certificates</button>
    </div>
  );
}

export default MailSender;
