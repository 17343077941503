import { useState } from "react";
import "../main.css"
import { getKey } from "../scripts/dataHandler";

function LoginPage({setAdminKey}) {

    let [password,setPassword] = useState("")
    let [msg,setMsg] = useState("")

    const logger = () => {
        getKey(password,setMsg,setAdminKey)
    }

    return ( <div className="container column centerAlign">
        <h1>login</h1>
        <input type="password" onInput={(e) => setPassword(e.target.value)}></input>
        <button onClick={logger}>enter</button>
        <h3>{msg}</h3>
    </div> );
}

export default LoginPage;