import Cookies from "universal-cookie";

import { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";

function App() {

  const cookies = new Cookies();

  let [adminKey,setAdminKey] = useState(undefined)

  const cookieSetter = (key) => {console.log(key);setAdminKey(key);cookies.set("key", key, { path: "/", maxAge: 16200 });}

  useEffect(() => {
    setAdminKey(cookies.get("key"))
  },[])

  return (
    <BrowserRouter>
    {adminKey ? 
      <MainPage adminKey={adminKey}/>
     : <LoginPage setAdminKey={cookieSetter}/>}
    </BrowserRouter>
  );
}

export default App;
