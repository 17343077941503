import { handleMsgReq } from "../../scripts/dataHandler"

function ChatMsgApproval({msgs, key}) {

    const mapMsgs = (msgs) => {
        return msgs.map(msg => {
           return <div className="column centerAlign" id={msg.id}>
                    <h3>{"Author: " + msg.authorName}</h3>
                    <h3>{"Msg: " + msg.msg}</h3>

            <div className="row">
                <button onClick={(e) => {handleMsgReq(key,msg.id,true);document.getElementById(msg.id).remove()}}>Potvrdit</button>
                <button onClick={(e) => {handleMsgReq(key,msg.id,false);document.getElementById(msg.id).remove()}}>Zahodit</button>
            </div>
            </div>
        })
    }

    return ( 
        <div className="column">
        {msgs ? mapMsgs(msgs) : <h2>zadne :(</h2>}
    </div>
     );
}

export default ChatMsgApproval;