const url = "https://omega-post-416123.ey.r.appspot.com/";
//const url = "http://localhost:8080/"

//ADMIN
export const getKey = (password,setMsg,setKey) => {
    setMsg("")
    fetch(url + "admin/getKey", {
        method: "POST",
        body: JSON.stringify({
          password: password
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (!json.key) {
            setMsg(json.msg);
          } else {
            setKey(json.key)
          }
        });
}

//GETTING STUFF
export const getTeams = (key, setMsg, setTeams) => {
  setMsg("")
  fetch(url + "admin/getTeams", {
    method: "POST",
    body: JSON.stringify({
      adminKey: key
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      if (!json.data) {
        setMsg(json.msg);
      } else {
        /*let teamIds = []

        let teamData = json.data.filter((e) => {if (teamIds.includes(e.id)) {return false} else {teamIds.push(e.id);return true}})
        setTeams(teamData)*/
        setTeams(json.data)
      }
    }); 
}

export const getTeamChangeReqs = (key, setChangeReqs) => {
  fetch(url + "admin/getTeamChangeReqs", {
    method: "POST",
    body: JSON.stringify({
      adminKey: key
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      setChangeReqs(json.data)
    }); 
}

export const getMsgReqs = (key, setMsgReqs) => {
  fetch(url + "admin/getMsgReqs", {
    method: "POST",
    body: JSON.stringify({
      adminKey: key
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      setMsgReqs(json.data)
    }); 
}

//DOING STUFF
export const deleteTeam = (key, teamName, setMsg) => {
  fetch(url + "admin/deleteTeam", {
    method: "POST",
    body: JSON.stringify({
      adminKey: key,
      teamName: teamName
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      setMsg(json.msg)
      window.location.reload()
    }); 
}

export const handleTeamChangeReq = (key,reqId, state) => {
  fetch(url + "admin/handleTeamChange", {
    method: "POST",
    body: JSON.stringify({
      adminKey: key,
      reqId: reqId,
      accepted: state
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      alert(json.msg)
      window.location.reload()
    }); 
}

export const handleMsgReq = (key, msgId, accepted) => {
  fetch(url + "admin/handleMsgReq", {
    method: "POST",
    body: JSON.stringify({
      adminKey: key,
      msgId: msgId,
      accepted: accepted
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      /*alert(json.msg)
      window.location.reload()*/
    });
}

export const generateCert = (key, teamData) => {
  fetch(url + "admin/generateCertificate", {
    method: "POST",
    body: JSON.stringify({
      adminKey: key,
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = "chichi.pdf";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
    })
}

export const generateCertificates = (key, selectedTeams) => {
  fetch(url + "admin/generateCertificates", {
    method: "POST",
    body: JSON.stringify({
      adminKey: key,
      selectedTeams: selectedTeams
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      alert(json.msg)
    })
}

export const sendGeneralMail = (key,subject,text,content,selectedTeams) => {
 fetch(url + "admin/sendGeneralMail", {
    method: "POST",
    body: JSON.stringify({
      adminKey: key,
      subject: subject,
      content: content,
      text: text,
      selectedTeams: selectedTeams
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      alert(json.msg)
      window.location.reload()
    });
}

export const sendTeamRecapMail = (key,selectedTeams) => {
  fetch(url + "admin/sendTeamRecapMail", {
     method: "POST",
     body: JSON.stringify({
       adminKey: key,
       selectedTeams: selectedTeams
     }),
     headers: {
       "Content-type": "application/json; charset=UTF-8",
     },
   })
     .then((response) => response.json())
     .then((json) => {
       alert(json.msg)
       window.location.reload()
     });
 }