import TeamCard from "../../components/TeamCard";

function TeamList({teams, key}) {

    const mapTeams = (teamData) => {
        let i = 0;
        return teamData.map((team) => {
            i+=1;
            return <TeamCard team={team} pos={i} key={i} adminKey={key}/>
        })
    }

    return (
    <div className="scrollView centerAlign column">
        <br/><br/><br/><br/>
        {teams && mapTeams(teams)}
    </div> );
}

export default TeamList;